import React from 'react'
import { Switch, Route } from 'react-router-dom';

// import Login from '../components/signin/index';
// import Register from '../containers/register/index';
// import Profile from '../containers/profile/index';
// import Trade from '../containers/trade/index';
// import Accounts from '../containers/accounts/index';
// import Transactions from '../containers/transactions/index';
// import Market from '../containers/market/index';
// import BookCall from '../containers/book/index';

import Login from '../App/Auth/Login';
import Register from '../App/Auth/Register';
import Profile from '../App/Pages/Profile';
import Transactions from '../App/Pages/Transactions';
import Accounts from '../App/Pages/Accounts';
import Trade from '../App/Pages/Trade';
import News from '../App/Pages/News';

import ForgotPassword from '../App/Auth/Login'; // '../containers/forgotPassword/index';
import ChangePassword from '../App/Auth/Login'; // '../containers/resetPassword/index';
import VerifyEmail from '../containers/verifyEmail/index';
import VerifyPhone from '../containers/verifyPhone/index';

import Profile2 from '../containers/profile/index';
import News2 from '../containers/news/index';
import Dashboard from '../containers/dashboard/index';
import Create from '../containers/lists/create';
import CreateAdmin from '../containers/createadmin/index';
import EditAdmin from '../containers/createadmin/index';
import CreateUser from '../containers/createadmin/users';
import CreateLead from '../containers/createadmin/users';
// import CreateClient from '../containers/createclient/client';
import Restrictions from '../containers/restrictions/index';
import Pricing from '../containers/pricing/index';
import Setup from '../containers/createclient/setup';
import Settings from '../containers/createclient/settings';
import Chats from '../containers/chats/index';
import Home from '../containers/home/index';
import PostNews from '../containers/postnews/index';
import AllTranasactions from '../containers/allTranasactions/index';
import Salesfunnel from '../containers/salesfunnel/index';
import Users from '../containers/users/index';
import Clients from '../containers/users/clients';
import Withdrawals from '../containers/withdrawals/index';
import Deposits from '../containers/withdrawals/index';
import DepositTo from '../containers/deposits/index';
import Activities from '../containers/activities/index';
import Admins from '../containers/admins/index';
import AdminsProfile from '../containers/adminsprofile/index';
import Finance from '../containers/finance/index';
import Calllogs from '../containers/calllogs/index';
import Forms from '../containers/forms/index';
import UsersProfileList from '../containers/usersprofile/index';
import Lists from '../containers/lists/index';
import Desks from '../containers/Desks/index';
import MailAccounts from '../containers/mailaccounts/index';
import Unsubscribers from '../containers/unsubscribers/index';
import TeamSettings from '../containers/teamsettings/index';
import Campaigns from '../containers/campaigns/index';
import Instruments from '../containers/instruments/index';
import Documents from '../containers/documents/index';
import Trades from '../containers/trades/index';
import app from '../services/app';

export default props => (
    app.isAdmin() ? (
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/Login' component={Login} />
        <Route exact path='/ForgotPassword' component={ForgotPassword} />
        <Route exact path='/ChangePassword' component={ChangePassword} />
        {app._aff_() ? null : <Route path='/News' exact component={News2} />}
        
        {app.master() ? <Route path='/Clients' exact component={Clients} /> : null}
        {app.master() ? <Route path='/Pricing/:cid' exact component={Pricing} /> : null}
        {app.master() ? <Route path='/Instruments' exact component={Instruments} /> : null}

        {app.super() ? <Route path='/Restrictions' exact component={Restrictions} /> : null}
        {app.super() ? <Route path='/Salesfunnel' exact component={Salesfunnel} /> : null}
        {app.super() || app.permit("Create admin") ? <Route path='/CreateAdmin' exact component={CreateAdmin} /> : null}
        {app.super() || app.permit("View and edit admin basic information") ? <Route path='/EditAdmin/:user_id' exact component={EditAdmin} /> : null}
        {app.super() || app.permit("Create user") ? <Route path='/CreateUser' exact component={CreateUser} /> : null}
        {app.super() || app.permit("Create user") ? <Route path='/CreateLead' exact component={CreateLead} /> : null}

        {app.permit("View chat") ? <Route path='/Chats' exact component={Chats} /> : null}
        {app.permit("Create news") ? <Route path='/PostNews' exact component={PostNews} /> : null}

        {app._aff_() ? null : <Route path='/Home' exact component={Home} />}
        {app._aff_() ? null : <Route path='/Admins/:desk' exact component={Admins} />}
        {app._aff_() ? null : <Route path='/Trades' exact component={Trades} />}
        {app._aff_() ? null : <Route path='/Transactions' exact component={AllTranasactions} />}
        {app._aff_() ? null : <Route path='/MailAccounts' exact component={MailAccounts} />}
        {app._aff_() ? null : <Route path='/Unsubscribers' exact component={Unsubscribers} />}
        <Route path='/Users/:desk' exact component={Users} />
        <Route path='/Leads/:desk' exact component={Users} />
        {app._aff_() ? null : <Route path='/Withdrawals' exact component={Withdrawals} />}
        {app._aff_() ? null : <Route path='/Deposits' exact component={Deposits} />}
        {app._aff_() ? null : <Route path='/DepositTo' exact component={DepositTo} />}
        {app._aff_() ? null : <Route path='/AdminsProfile/:user_id' exact component={AdminsProfile} />}
        {app._aff_() ? null : <Route path='/Finance' exact component={Finance} />}
        {app._aff_() ? null : <Route path='/TeamSettings' exact component={TeamSettings} />}
        {app._aff_() ? null : <Route path='/Forms' exact component={Forms} />}
        <Route path='/UsersProfile/:user_id' exact component={app._aff_() ? Desks : UsersProfileList} />
        <Route path='/Desks' exact component={Desks} />
        <Route path='/Desk/:desk' exact component={Lists} />
        {app._aff_() ? null : <Route path='/Create' exact component={Create} />}
        {app._aff_() ? null : <Route path='/Documents' exact component={Documents} />}
        <Route path='/Profile' exact component={Profile2} />
        {app._aff_() ? null : <Route path='/Settings/:tab' exact component={Settings} />}
        {app._aff_() || !app.super() ? null : <Route path='/Settings' exact component={Setup} />}
        
        {/* <Route path='/Accounts' exact component={Accounts} /> */}
        {/* <Route path='/Dashboard' exact component={Dashboard} /> */}
        {/* {app.custom("master") ? <Route path='/CreateClient' exact component={CreateClient} /> : null} */}
        {/* <Route path='/Salesfunnel' exact component={Salesfunnel} /> */}
        {/* <Route path='/Activities' exact component={Activities} /> */}
        {/* <Route path='/Campaigns' exact component={Campaigns} /> */}
        {/* <Route path='/Calllogs' exact component={Calllogs} /> */}
        {/* <Route path='/Lists' exact component={Lists} /> */}

      </Switch>
    ) : app.isVerified() || true ? (
        <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/Login' component={Login} />
            <Route exact path='/Register' component={Register} />
            <Route exact path='/VerifyEmail' component={VerifyEmail} />
            <Route exact path='/VerifyPhone' component={VerifyPhone} />
            <Route exact path='/Trade' component={Trade} />
            <Route exact path='/Accounts' component={Accounts} />
            <Route exact path='/Profile' component={Profile} />
            <Route exact path='/ForgotPassword' component={ForgotPassword} />
            <Route exact path='/ChangePassword' component={ChangePassword} />
            <Route exact path='/Transactions' component={Transactions} />
            <Route exact path='/News' component={News} />
        </Switch>
    ) : (
        <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/Login' component={Login} />
            <Route exact path='/Register' component={Register} />
            <Route exact path='/ForgotPassword' component={ForgotPassword} />
            <Route exact path='/ChangePassword' component={ChangePassword} />
            <Route exact path='/VerifyEmail' component={VerifyEmail} />
            <Route exact path='/VerifyPhone' component={VerifyPhone} />
            <Route exact path='/Profile' component={Profile} />
        </Switch>
    )
)
