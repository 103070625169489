import React, { Component } from 'react';
import create from '../../../Assets/create.svg';
import app from '../../../services/app';
import server from '../../../services/server';

export default class AccountDetails extends Component {
    constructor(props) {
    super(props);

    let p = app.profile();
    this.state = {
      account_name: p["account_name"] || "",
      account_number: p["account_number"] || "",
      bank_name: p["bank_name"] || "",
      bank_address: p["bank_address"] || "",
      bank_SWIFT_code: p["bank_SWIFT_code"] || "",
      bank_IBAN: p["bank_IBAN"] || "",
      profile: p,
      saveD: false
    };
  }

  componentDidMount () {}

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      saveD: true
    });
  }

  saveDetails = async (e) => {
    e.preventDefault();
    this.props.showSpinner();
    try {
      let bd = this.state;
      delete bd.profile;
      delete bd.saveD;
      console.log(bd);
      await server.setBankingDetails(bd);
      const gp = await server.getProfile();
      app.profile(gp.data.profile);
      this.setState({profile: gp.data.profile});
      this.props.showSpinner();
      this.props.done();
    } catch (err) {
      this.props.showSpinner();
      return err;
    }
  }

  render () {
    return (
      <>
        <h3 className="astt">Banking Details</h3>
        <form align="left" className="dform" style={{position: "relative"}}>
            {this.state.saveD ? <button onClick={this.saveDetails} type="button" className="tool-i">Save Details</button> : <img src={create} className="tool-i" />}
            <div className="row">
                <div className="col">
                    <div className="form-group" style={{width: "75%"}}>
                        <label>ACCOUNT NAME</label>
                        <input type="text" className="form-control" name="account_name" defaultValue={this.state.account_name} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" style={{width: "75%"}}>
                        <label>BANK SWIFT CODE</label>
                        <input type="text" className="form-control" name="bank_SWIFT_code" defaultValue={this.state.bank_SWIFT_code} onChange={this.handleChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group" style={{width: "75%"}}>
                        <label>ACCOUNT NUMBER</label>
                        <input type="text" className="form-control" name="account_number" defaultValue={this.state.account_number} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" style={{width: "75%"}}>
                        <label>BANK ADDRESS</label>
                        <input type="text" className="form-control" name="bank_address" defaultValue={this.state.bank_address} onChange={this.handleChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group" style={{width: "75%"}}>
                        <label>BANK NAME</label>
                        <input type="text" className="form-control" name="bank_name" defaultValue={this.state.bank_name} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" style={{width: "75%"}}>
                        <label>BANK IBAN</label>
                        <input type="text" className="form-control" name="bank_IBAN" defaultValue={this.state.bank_IBAN} onChange={this.handleChange} />
                    </div>
                </div>
            </div>
        </form>         
      </>
  );
};

}
