import React from "react";
import Image from "../../themes/images/tradeDashboard/open_box.svg";
import "./index.scss";

const TradeNotFound = ({ text, nobox }) => {
  return (
    <div className="trade-not-found-box">
      {nobox ? null : <img src={Image} alt="box" />}
      <p>{text}</p>
    </div>
  );
};

export default TradeNotFound;
