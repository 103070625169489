import $ from 'jquery';
import moment from 'moment';
import axios from 'axios';
import tz from './tz.json';
import arabic from './arabic.json';

const app = {
  live: true,
  uid: (u) => {
    u = u.split("-");
    return u[u.length - 1].toUpperCase();
  },
  rval: (r) => {
    return r.split(" ").join("_").toLowerCase();
  },
  real: (b, r) => {
    let ret = Number(Number(b)+Number(r)).toFixed(2);
    return isNaN(ret) ? "0.00" : ret;
  },
  nwc: (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  ref: (r) => {
    let s = r;
    try {
      s = r.replace(/(undefined)/g, "0");
    } catch (e) {
      console.log(e, "-- replace err");
    } return s;
  },
  id: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile.user_id;
  },
  spread: (x) => {
    let z = Number(x.bid) - Number(x.ask);
    return app.floatFormat(z > 0 ? z : z * -1);
  },
  symbol: (x = '', alt = false) => {
    let sb = {usd: "$", eur: "€", gbp: "£", ngn: '₦'};
    let ac = app.data("afc").split(",");
    let sc = [];
    
    if(ac.indexOf("usd") > -1) { sc.push({"USD": "USD ($)"}); }
    if(ac.indexOf("eur") > -1) { sc.push({"EUR": "EUR (€)"}); }
    if(ac.indexOf("gbp") > -1) { sc.push({"GBP": "GBP (£)"}); }
    if(ac.indexOf("ngn") > -1) { sc.push({"NGN": "NGN (₦)"}); }

    if(alt) {
      let ret = 'usd';
      Object.entries(sb).forEach((va, ki) => {
        if(va[1] == x) {
          ret = va[0];
        }
      });
      return ret.toUpperCase();
    }
    return x.length ? (sb[x.toLowerCase()] || x) : sc;
  },
  currency: (x = false, adm = false) => {
    if(app.isAdmin() || adm) {
      let c = app.data("afc").split(",");
          c = c.length ? c[0].toUpperCase() : "USD";
      return x ? c : app.symbol(c);
    } else {
      let c = app.accountDetail()["currency"] || "USD";
      return x ? c.toUpperCase() : app.symbol(c);
    }
  },
  loggedIn: () => {
    return !!localStorage.getItem("avariz_profile");
  },
  time: () => {
    return new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
  },
  name: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return (profile.first_name+" "+profile.last_name).ucwords();
  },
  messageCount: (a = -1) => {
    if(a > 0) {
      localStorage.setItem("avariz_mcnt", a); return a;
    } else if(a < 0) {
      return Number(localStorage.getItem("avariz_mcnt") || 0);
    } else {
      return localStorage.removeItem("avariz_mcnt");
    }
  },
  l: (_f) => {
    if(arabic[_f] && app.host() == "trade.ifgmarkets.com") {
      return arabic[_f];
    } else {
      // console.log(_f, "<<Translate>>");
      return _f;
    }
  },
  isVerified: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    return !profile ? 0 : profile.kyc;
  },
  frozen: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    return !profile ? 0 : Number(profile.freeze);
  },
  userid: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile.user_id;
  },
  blur: (t = "") => {
    let l = t.length;
    return app.permit("Blur contact information (email and phone number)") || app._aff_() ? t : "*".repeat(l);
  },
  auth: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile.user_id;
  },
  leverage: (x = null) => {
    let ret;
    if(x) {
      ret = "1:200";
      window.current_accounts.forEach((acc2) => {
        if($("#adm-uid-tra").val() == acc2["account_name"].toLowerCase()) {
          ret = acc2["leverage"];
        }
      });
    } else {
      ret = app.accountDetail()?.leverage;
    }
    return ret ? parseInt(ret.replace("1:", "")) : null;
  },
  account: (a = "", live = false) => {
    // if(app.isAdmin()) return "*";
    if(a.length) {
      if(window.WebSocketPlugged) {
        window.WebSocketPlug.close();
      }
      localStorage.setItem("avariz_active", a);
      $(window).trigger("reconnect");
      return a;
    }
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    if(!profile.accounts) return "";
    if(!profile.accounts.length) return "";
    if(live) {
      return profile.accounts.filter((pa) => {
        if(pa.account_name.split("-")[0].toLowerCase() == "live") {
          return pa;
        }
      })[0]["account_name"];
    }
    if(localStorage.getItem("avariz_active") && Object.values(app.accounts()).indexOf(localStorage.getItem("avariz_active")) > 0) {
      return localStorage.getItem("avariz_active");
    } else {
      localStorage.setItem("avariz_active", profile.accounts[0].account_name);
      return profile.accounts[0].account_name;
    }
  },
  accountDetail: (acc = null) => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    if(!profile.accounts) return [];
    if(!profile.accounts.length) return [];
    let selectd = acc ? acc : (app.account() || profile.accounts[0].account_name);
    let ret     = null;
    profile.accounts.forEach((acc2) => {
      // console.log(selectd, acc2, "<<<")
      if(selectd.toLowerCase() == acc2["account_name"].toLowerCase()) {
        ret = acc2;
      }
    });
    return ret;
  },
  allPairs: (a = null) => {
    return a ? localStorage.setItem('avariz_pairs', JSON.stringify(a)) : JSON.parse(localStorage.getItem('avariz_pairs') || '{}');
  },
  accounts: (a = null) => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(a || a == []) {
      profile.accounts = a;
      localStorage.setItem("avariz_profile", JSON.stringify(profile));
      profile = JSON.parse(localStorage.getItem("avariz_profile"));
    }
    if(!profile) window.location.href = "/login";
    let accounts = [];
    profile.accounts.forEach((acc) => {
      accounts.push(acc["account_name"]);
    });
    return accounts;
  },
  accountList: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile.accounts;
  },
  messages: (m = null) => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(m) {
      profile.messages = m;
      localStorage.setItem("avariz_profile", JSON.stringify(profile));
      profile = JSON.parse(localStorage.getItem("avariz_profile"));
      return profile.messages;
    }
    if(!profile) window.location.href = "/login";
    return profile.messages || [];
  },
  email: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile.email.trim();
  },
  phone: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile.phone_number.trim();
  },
  retryLimit: 10,
  maxrow: Number(localStorage.getItem("avariz_pager")) || 5,
  page_size: () => {
    return Number(localStorage.getItem("avariz_pager")) || 5;
  },
  permitted: (x) => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(profile.permissions == "*" || profile.restrictions == "!*") {
      return true;
    }
    let p = profile.permissions.split(",");
    let r = p.filter((_p) => _p.toLowerCase().trim() == x);
    return r.length ? true : false;
  },
  permit: (x) => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(profile.restrictions == "!*") {
      return true;
    }
    let p = profile.restrictions.split(",");
    let r = p.filter((_p) => _p.toLowerCase().trim() == app.rval(x));
    return r.length > 0;
  },
  super: () => {
    let profile = JSON.parse(localStorage.getItem("avariz_profile")) || false;
    if(!profile) return false;
    return profile.permissions == "*" || profile.restrictions == "!*";
  },
  master: (r = false) => {
    let em = "sjigem@gmail.com"
    if(r) {
      return em;
    }
    return app.super() && app.profile().email == em;// && ($("body").data("host") == "root.devfx.co" || $("body").data("host") == "*");
  },
  status: (i) => {
    return i == "-1" ? "Rejected" : ["Pending", "Processing", "Successful"][i];
  },
  setMaxrow: (r) => {
    localStorage.setItem("avariz_pager", r);
  },
  profile: (p = null) => {
    if(p) {
      localStorage.setItem('avariz_profile', JSON.stringify(p));
    }
    let profile = JSON.parse(localStorage.getItem("avariz_profile"));
    if(!profile) window.location.href = "/login";
    return profile;
  },
  questions: () => {
    return [
      {question: "What kind of experience / knowledge do you have in financial markets", answers: ["Traded on stocks", "Traded on binary option", "Traded on forex/CFD", "Traded on crypto", "None"]},
      {question: "What's your main source of income", answers: ["Retired / pensioner", "Student", "Unemployed", "Self-employed", "Civil servant"]},
      {question: "Are you politically exposed", answers: ["Yes", "No"]},
      {question: "Are you a US citizen", answers: ["Yes", "No"]},
      {question: "Are you interested in manual trading or Automatic trading software", answers: ["Automatic trading software", "Manual trading"]},
    ];
  },
  info: (i = null) => {
    if(i) {
      localStorage.setItem('avariz_info', JSON.stringify(i));
    }
    return JSON.parse(localStorage.getItem("avariz_info"));
  },
  daysToGo: (raw = false) => {
    if(raw && app.master()) {
      return 1000;
    }
    let o  = $("body").data("oxp");
    let x  = $("body").data("exp");
    var d  = parseInt($("body").data("dxp")), d = d > 0 ? d : 1;
    let fr = moment(o.split(" ")[0]).format('DD/MM/YYYY');
    let td = moment(app.time()).format('DD/MM/YYYY');
    let ex = x.length ? x : moment(o).add(d, 'M').format('DD/MM/YYYY');
    var startDate = moment(String(td), "DD/MM/YYYY");
    var endDate = moment(String(ex), "DD/MM/YYYY");
    var dateDiff = endDate.diff(startDate, "days");
    return raw ? parseInt(dateDiff) : dateDiff+" day"+(dateDiff > 1 ? 's' : '')+" left";
  },
  isAdmin: () => {
    return localStorage.getItem("avariz_ia") || false;
  },
  mute: () => {
    return parseInt($("body").data("mut"));
  },
  _aff_: () => {
    if(app.isAdmin()) {
      if(app.profile()["permissions"] == "_aff_" || app.profile()["restrictions"] == "_aff_") {
        return true;
      }
    }
    return false;
  },
  logo: () => {
    return $("body").data("logo");
  },
  host: (x = true) => {
    return app.master() && x ? "*" : $("body").data("host");
  },
  book: () => {
    return $("body").data("abc").toLowerCase() == "a & b-book";
  },
  statusType: (u, drop = false) => {
    let stp = Object.assign($("body").data('stat'));
    if(drop) {
      return stp;
    }
    let i = u.astat;
    if(stp.length && i > 0) {
      let sta = stp.filter((st) => parseInt(st.id) == i);
      console.log(u, sta);
      if(sta.length) {
        sta = sta[0];
        return <span id={"user-asta-"+u.user_id} className={'presence-tx'} style={{background: sta.badge, border: "0px"}}>{sta.sname}</span>
      }
    }
    return null;
  },
  data: (d) => {
    return $("body").data(d);
  },
  custom: (i) => {
    try {
      let u = {
        logo: "l",
        name : "n",
        primary : "p",
        secondary : "s",
        master: "m"
      };
      let cs = {
        l : $("body").data("logo"),
        n : $("body").data("title"),
        p : $("body").data("primary"), // "#00A880"
        s : $("body").data("secondary"), // "#00A880"
        m : app.master()
      };
      return cs[u[i]] || $("body").data(i);
    } catch(e) {
      if(window.location.href.split("/")[window.location.href.split("/").length - 1].toLowerCase() != "login") {
        window.location.href = "Login";
      }
      return e;
    }
  },
  presence : (u, t = false) => {
    return u ? (
      <span id={"user-pre-sta-"+u.user_id} className={'presence-t'+(t ? 'x' : 'd')+' '+(u.online ? "online" : "offline")}>{t ? (u.online ? "Online" : "Offline") : ""}</span>
    ) : null
  },
  switch_presence : (id, o) => {
    $("#user-pre-sta-"+id).attr("class", "presence-td "+(o ? "online" : "offline"));
  },
  ntz : () => {
    let t = tz.filter((z) => {
      return z.value.toLowerCase() == Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
    });
    let date = new Date;
    let strTime = date.toLocaleString("en-US", {timeZone: `${(app.profile()["utz"] || Intl.DateTimeFormat().resolvedOptions().timeZone)}`});
    if(t.length) {
      return moment(strTime).format("h:mm a")+t[0].name.split(")")[0]+")";
    } else {
      return moment(strTime).format("h:mm a");
    }
  },
  hostURL: (url, type = 0) => {
    // let rd = Number(Math.floor(Math.random() * 4)+1);
    let isHome = window.location.pathname.toLowerCase().match("home");
    let live = app.live;
    if(type > 0) {
      let join = app.isAdmin() ? app.userid()+"/*" : app.userid()+"/"+app.account();
      if(app.isAdmin() && app.account().length && isHome) {
        if(window.FAKE_UID?.length) {
          join = app.userid()+"_"+window.FAKE_UID+"/"+app.account();
        } else {
          join = app.userid()+"/*";
        }
      }
      let epag = "/"+window.location.pathname.split("/")[1].toLowerCase();
      join = join+"/"+app.host()+(app.isAdmin() ? epag : "");
      return (live ? "wss://fx-socket-"+(app.isAdmin() ? "two" : "one")+".herokuapp.com/" : "ws://localhost:3003/")+join;
    } else {
      return live ? "https://fx-server-one.herokuapp.com/"+url : "http://localhost:3004/"+url;
      // return "http://localhost:3004/"+url;
    }
  },
  cpage: () => {
    return window.location.pathname.split("/")[1].toLowerCase();
  },
  version: () => {
    return "3.0.3";
  },
  split_me: (str, param, ind = 0) => {
    if(str.length) {
      let _str = str.split(param);
      if(_str.length > ind) {
        return _str[ind];
      }
    }
    if(ind == 0) {
      return str;
    }
  },
  c_date_time: (u_date) => {
    if(app.isAdmin()) {
      return u_date;
    }
    function getOffsetBetweenTimezonesForDate(date, timezone1, timezone2) {
      const timezone1Date = convertDateToAnotherTimeZone(date, timezone1);
      const timezone2Date = convertDateToAnotherTimeZone(date, timezone2);
      return (timezone1Date.getTime() - timezone2Date.getTime()) / 1000 / 60;
    }
      
    function convertDateToAnotherTimeZone(date, timezone) {
        const dateString = date.toLocaleString('en-US', {
            timeZone: timezone
        });
        return new Date(dateString);
    }

    let sel_tz = app.profile()["utz"] || "";

    if(sel_tz.length) {
      let date = new Date;
      let def_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let xdiff = date.getTimezoneOffset() == app.profile()['utc'];
      let mdiff = getOffsetBetweenTimezonesForDate(date, sel_tz, def_tz);
      let rdate = moment(u_date.replace("?", " "));
          rdate = rdate.add(mdiff, "minutes").format("DD/MM/YYYY, hh:mm:ss A");
      return rdate == "Invalid date" ? u_date : rdate;
    } else {
      let ret_date = moment(u_date.replace("?", " ")).format("DD/MM/YYYY, hh:mm:ss a");
      return ret_date == "Invalid date" ? u_date : ret_date;
    }
  },
  perc: (x, y) => {
    x = Number(x);
    return x > 0 ? Number((x / 100) * y) : 0;
  },
  coma: (x) => {
    let str = "";
    var parts = String(x).split(".");
    if(String(parts[0]).length > 3) {
      String(parts[0]).split("").reverse().forEach((p, k) => {
        str += p+(k % 3 == 2 ? "," : "");
      })
      str = String(str).split("").reverse().join("")+(parts.length > 1 ? "."+String(parts[1]) : "");
      if(str.charAt(0) == ",") {
        str = str.substring(1, str.length);
      }
    } else {
      str = x;
    }
    return str;
  },
  time: () => {
    let d  = new Date(),
        vt = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
        vc = d.getTimezoneOffset();
        return vt;
  },
  cleanDate: (d, s = -1) => {
    d = d.trim().replace(/-/g, "/").replace(/,/g, "");
    return s > -1 && d.length ? d.split(" ")[0] : d;
  },
  cleanTime: (d) => {
    return d.trim().replace(/,/g, "").split(" ")[1];
  },
  altField: (f) => {
    return f ? (f.trim().length ? f : "NOT STATED") : "NOT STATED";
  },
  floatFormat: (x, dp = 5, txt = false) => {
    if(dp < 0) {
      let rst = String(x);
      return Number(rst.substr(0, rst.length+dp));
    }
    let currency = parseFloat(parseFloat(x).toFixed(dp));
    return txt ? currency.toLocaleString('en-US', {minimumFractionDigits: dp}) : currency;
  },
  fxRate: (x) => {
    x = String(x);
    return Number(x.substr(0, 7));
  },
  noCache: (x = "/") => {
    return (x != "/" ? x+'nocache=' : '')+(new Date().getTime());
  },
  guessTimate: (x, raw = false) => {
    let str = String(x).substr(0, 7);
    let chill = Math.floor((Math.random() * 100) + 1);
    if(!raw || chill <= 30) {
      return Number(str);
    }
    var y = Math.random();
        y = y < 0.5 ? Math.floor(y) : Math.ceil(y);
    let bfd = parseInt(str);
    let afd = str.split(".")[1];
    if(afd && afd.length > 1) {
      let scp = Math.floor((Math.random() * 5));
      let af_ = String(Number(afd)+(y ? scp : -scp));
      let rpt = afd.length-af_.length;
      let _fd = Number(String(bfd)+"."+(rpt > 0 ? "0".repeat(rpt) : "")+af_);
      return _fd;
    } else {
      return Number(str);
    }
  },
history: async (pair, interval, body) => {
    let _int    = interval;
    let intv    = _int.length ? _int : interval;
    // let intv    = "5m";
    let from    = body.from;
    let to      = body.to;
    let url     = "https://query1.finance.yahoo.com/v8/finance/chart/";

    if(pair.indexOf("_") > -1) {
        pair = pair.replace("_", "");
        url += pair+'=X?symbol='+pair+'%3DX&period1='+from+'&period2='+to+'&interval='+intv;
    } else {
        url += pair+"?symbol="+pair+"&period1="+from+"&period2="+to+"&interval="+intv;
        url = encodeURI(url);
    }

    console.log(_int, url);

    try {
        return await axios
        .get(url)
        .then(resp => {
          let data = {success: true, result: resp.data.chart.result[0]};
          console.log(data);
          return(data);
        });
    } catch(e) {
      let data = {success: false, result: [], e: e};
      console.log(data);
      return(data);
    }
  }
};

export default app;
