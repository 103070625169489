import React, { Component } from 'react';
import $ from 'jquery';
import { Link, withRouter } from 'react-router-dom';
import Spinner from '../../components/spinner/index';
import server from '../../services/server';
import app from '../../services/app';
import { saveUserProfile } from '../../redux/actions/index';
import { CallBack } from '../../components/popups/index';
import AccountDetails from './Components/accountDetails';
import AddCard from './Components/addCard';
import DebitCard from './Components/debitCard';
import Container from '../Container/Index';
// import avatar from '../../Assets/avatar.png';
import avatar from '../../themes/images/tradeDashboard/t_nav3.png';
import loader from '../../Assets/loader.svg';
import upd from '../../Assets/upload.svg';
import upv from '../../Assets/upv.svg';
import ed from '../../Assets/edit.svg';
import cc from '../../Assets/cc.png';
import add from '../../Assets/add.svg';
import check from '../../Assets/check.svg';
import star from '../../Assets/star.svg';
import fstar from '../../Assets/fav.svg';


import bsell from '../../Assets/btab/bsell.svg';
import market from '../../Assets/btab/market.svg';
import order from '../../Assets/btab/order.svg';
import trade from '../../Assets/btab/trade.svg';
import wallet from '../../Assets/btab/wallet.svg';

import bsellA from '../../Assets/btab/bsell-active.svg';
import marketA from '../../Assets/btab/market-active.svg';
import orderA from '../../Assets/btab/order-active.svg';
import tradeA from '../../Assets/btab/trade-active.svg';
import walletA from '../../Assets/btab/wallet-active.svg';



import './Profile.scss';


class Profile extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        userData: {},
        showSpinner: false,
        selectedAccount: '',
        showBoxes: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        oldPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
        error: null,
        accu: false,
        ever: false,
        updateC: false,
        success: false,
        verified: app.isVerified(),
        imageUrl: '',
        showCallBack: false,
        showCallBackTxt: '',
        callBack_is_err: false,
        image: '',

        btab: 'profile',
        profile: app.profile(),
        profile_image: '',
        showSmallSPinner: false,
        showAddCardModal: false,
      };
  
      this.profile         = app.profile();
      this.selectedAccount = app.accountDetail();
      this.id              = app.id();
      this.unverifiedItems = [];
    }
  
    async componentDidMount() {
      if (!app.id().length) this.props.history.push('/Login');
  
      if(window.changePassword) {
        this.setState({showBoxes: true});
        window.changePassword = false;
      }

      $(".block input").on("keyup", () => {
          this.setState({updateC: true});
      });
  
      $(window).on("changePassword", () => {
        this.setState({showBoxes: true});
        window.changePassword = false;
      });
  
      $(window).on("veripopcon", (e) => {
        if(window.vdv && window.vdv == 3 && window._veripopcon == window.lab) {
          this.setState({showCallBack: true});
          setTimeout(() => {
            window.location.href = "";
          }, 6000);
        } else {
          window.location.href = "";
        }
      });
  
      $(document).delegate(".uis .data-value", "keyup", function () {
        $(this).addClass("edited");
        $("._sdt").parent().css({display: 'flex'});
      });
  
      const gp = await server.getProfile();
      app.profile(gp.data.profile);
      this.profile         = app.profile();
      this.selectedAccount = app.accountDetail();
  
      this.setState({verified: this.profile.kyc > 0, profile: gp.data.profile})
      saveUserProfile(this.profile);
    }
  
    handleInputChange = (e) => {
      const { name, value } = e.target;
  
      this.setState({
        [name]: value,
      });
    };
  
    handleFileChange = async (e) => {
      const current = e.target.files[0];
      const fd = new FormData();
      fd.append('profile_doc.png', current, current.name);
  
      this.setState({ showSmallSPinner: true });
      try {
        let pi = await server.uploadImage(fd);
        const gp = await server.getProfile();
        app.profile(gp.data.profile);
        saveUserProfile(gp.data.profile);
        this.setState({ showSmallSPinner: false, profile_image: gp.data.profile.profile_image, profile: gp.data.profile });
        // window.location.href = "";
      } catch (error) {
        this.setState({ showSmallSPinner: false });
        return error.message;
      }
    };

    refreshCards = async () => {
      const gp = await server.getProfile();
      app.profile(gp.data.profile);
      saveUserProfile(gp.data.profile);
      this.setState({ showAddCardModal: false, profile: gp.data.profile });
    }
    
    handleDocChange = async (e, folder, name = null) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        if(selectedFile.size > 5000000) {
          return this.setState({ showCallBack: true, showCallBackTxt: "FIle size should not be more than 5MB", callBack_is_err: true, showSpinner: false, showSmallSPinner: false});
        }
        const current = e.target.files[0];
        const fd = new FormData();
        if(current) {
          console.log(current.name, current);
            fd.append(current.name, current, current.name);
            try {
              $("#verify-img-"+folder).attr("src", loader).addClass("force");
              $("#verify-span-"+folder).html("Uploading...");
              let sp = await server.uploadDoc(fd, folder, null, current.name);
              if(sp.data.success) {
                const gp = await server.getProfile();
                app.profile(gp.data.profile);
                saveUserProfile(gp.data.profile);
                this.setState({ showSmallSPinner: false, callBack_is_err: false, profile: gp.data.profile });
              } else {
                let mes = sp.data.message;
                console.log(mes);
                this.setState({ showCallBack: true, showCallBackTxt: mes, callBack_is_err: true, showSpinner: false, showSmallSPinner: false});
              }
              $("#verify-span-"+folder).html("Re-upload");
              $(".force").removeClass("force");
            // window.location.href = "";
            } catch (error) {
              console.log(error, "/2/");
              this.setState({ showCallBack: true, showCallBackTxt: "An error occurred", callBack_is_err: true, showSpinner: false, showSmallSPinner: false});
              return error.message;
            }
        }
    };

    
  handleSubmit = async (e, folder, name = null) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];
    if(selectedFile.size > 5000000) {
      return this.setState({ showCallBack: true, showCallBackTxt: "FIle size should not be more than 5MB", callBack_is_err: true, showSpinner: false, showSmallSPinner: false});
    }
    const formData = new FormData();
    formData.append(selectedFile.name, selectedFile);
    try {
      $("#verify-img-"+folder).attr("src", loader).addClass("force");
      $("#verify-span-"+folder).html("Uploading...");

      console.log("before send/");
      const sp = await server.upload_doc(formData, folder, selectedFile.name);
      if(sp.data.success) {
        const gp = await server.getProfile();
        app.profile(gp.data.profile);
        saveUserProfile(gp.data.profile);
        this.setState({ showSmallSPinner: false, callBack_is_err: false, profile: gp.data.profile });
      } else {
        let mes = sp.data.message;
        console.log(mes);
        this.setState({ showCallBack: true, showCallBackTxt: mes, callBack_is_err: true,  showSpinner: false, showSmallSPinner: false});
      }
      $("#verify-span-"+folder).html("Re-upload");
      $(".force").removeClass("force");
    } catch(error) {
      console.log(error, "/2/");
      this.setState({ showCallBack: true, showCallBackTxt: "An error occurred", callBack_is_err: true, showSpinner: false, showSmallSPinner: false});
      return error.message;
    }
  }

    verificationBox = ({name, folder, label, status}) => {
        return (
            <>
                <div className="col"><span><img src={check} /><b>{label}</b></span>
                <label className={"btn"+(status ? ' s' : '')} htmlFor={"verify-"+folder}>
                    <img src={upv} id={"verify-img-"+folder} className="upv" /><span id={"verify-span-"+folder}>{status ? 'Re-' : ''}Upload</span></label>
                </div>
                <input type="file" name={name} onChange={(e) => this.handleDocChange(e, folder, name)} id={"verify-"+folder} />
            </>
        )
    }

    sendEOTP = async () => {
      this.setState({showSpinner: true});
      try {
        let sm = await server.sendEOTP(this.id);
        this.setState({showCallBack: true, ever: true, showSpinner: false, updateC: false});
      } catch (e) {
        this.setState({showSpinner: false, updateC: true});
        return e;
      }
    }

    updateDetails = async () => {
      let valus = {};
      let ecl = $(".block .editable").length;
      if(ecl) {
        $(".block .editable").each(function(v) {
          if($(this).attr("name").length) {
            valus[$(this).attr("name")] = $(this).val().trim();
          }
        });
        setTimeout(async () => {
          this.setState({showSpinner: true});
          try {
            let sm = await server.updateUserDetails(this.id, valus);
            $("._sdt").parent().css({display: 'none'});
            const gp = await server.getProfile();
            app.profile(gp.data.profile);
            this.profile         = app.profile();
            this.selectedAccount = app.accountDetail();
            this.setState({accu: true, profile: gp.data.profile, showCallBack: true, showCallBackTxt: sm.data.message, callBack_is_err: false, showSpinner: false, updateC: false});
          } catch (e) {
            this.setState({showSpinner: false, updateC: true});
            return e;
          }
        }, 50);
      }
    };
  
    hidePasswordBoxes = () => {
      this.setState({
        showBoxes: !this.state.showBoxes,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        oldPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
        error: null,
      });
    };
  
    clearErrors = () => {
      this.setState({
        oldPasswordError: null,
        newPasswordError: null,
        confirmPasswordError: null,
        error: null,
        success: false,
      });
    };
  
    changePassword = async () => {
      this.clearErrors();
      const { oldPassword, newPassword, confirmPassword } = this.state;
  
      if (oldPassword === '') return this.setState({ error: 'Old password required' });
      if (newPassword === '') return this.setState({ error: 'New password required' });
      if (confirmPassword === '') return this.setState({ error: 'Please confirm new password' });
      if (newPassword === oldPassword) return this.setState({ error: 'New password cannot be old password' });
      if (newPassword !== confirmPassword) return this.setState({ error: 'Passwords must match' });
  
      if (oldPassword && newPassword === confirmPassword) {
        try {
          this.setState({ showSpinner: true });
  
          const user_id = app.id();
          const email   = this.profile.email;
  
          await server.changePassword({old_password: oldPassword, new_password: newPassword});
          this.setState({
            showSpinner: false,
            success: true,
            newPassword: '',
            oldPassword: '',
            confirmPassword: '',
          });
          $("[name=newPassword], [name=oldPassword], [name=confirmPassword]").val("");
        } catch (error) {
          if (!error.response) {
            return error.message;
          }
          const errorMessage = error.response.data.message;
          this.setState({ showSpinner: false, error: errorMessage });
        }
      }
    };
  
    deleteCard = async (id, cardPAN) => {
      try {
        this.setState({ showSpinner: true });
        await server.deleteCard(id, cardPAN);
        this.refreshCards();
        this.setState({ showSpinner: false });
      } catch (error) {
        this.setState({ showSpinner: false });
        return error.message;
      }
    };
  
    render() {
      const userId = app.id();
  
      if (!userId) return <Redirect to='/Login' />;
  
      let { showSpinner, profile, showAddCardModal, updateC, btab } = this.state;
  
      let {
        profile_image,
        first_name,
        kyc,
        last_name,
        email,
        phone_number,
        country,
        gender,
        lang,
        modify_time,
        dob,
        city,
        cards,
        user_id,
        identity_proof,
        residence_proof,
        dod,
        bank_card,
        email_verified
      } = profile;
  
      let balance   = this.selectedAccount.balance;
      let id        = app.id();
      let accountId = app.accountDetail().account_name;
      let uid       = app.userid().split("-");
  
      const userData = [
        {
          dataKey: 'First Name',
          value: `${first_name}`,
          name: 'first_name',
          editable: true,
          fixed: true,
        },
        {
          dataKey: 'Last Name',
          value: `${last_name}`,
          name: 'last_name',
          editable: true,
          fixed: true,
        },
        {
          dataKey: 'User ID',
          value: uid[uid.length - 1].toUpperCase(),
          editable: true,
          fixed: true,
        },
        {
          dataKey: 'Country',
          value: country,
          editable: true,
          fixed: true,
        },
        {
          dataKey: 'Email',
          value: email,
          name: 'email',
          editable: true,
          fixed: false,
        },
        {
          dataKey: 'Phone',
          value: phone_number,
          name: 'phone_number',
          editable: true,
          fixed: false,
        },
        {
          dataKey: 'City',
          value: city,
          name: 'city',
          editable: true,
          fixed: false,
        },
        {
          dataKey: 'Date of birth',
          value: dob,
          name: 'dob',
          editable: true,
          fixed: false,
        },
      ];
  
      const unverified = [];
  
      if (!parseInt(String(residence_proof).length)) {
        unverified.push('Upload Proof of Address');
      }
  
      if (!parseInt(String(bank_card).length)) {
        unverified.push('Upload Bank Card');
      }
  
      if (!parseInt(String(dod).length)) {
        unverified.push('Upload Declaration of Deposit');
      }
  
      if (!parseInt(String(identity_proof).length)) {
        unverified.push('Upload Proof of Identity');
      }

      // if (!email_verified) {
      //   unverified.push('Verify Email Address');
      // }
  
      const verificationData = [
        {
          itemHead: 'Upload Proof of Identity',
          itemContent: 'Upload ID Card or Passport',
          buttonText: 'Upload',
          folder: 'identity-proof',
          verified: parseInt(String(identity_proof).length),
          name: "doc_poi"
        },
        {
          itemHead: 'Upload Proof of Residence',
          itemContent: 'Utility Bill or Bank statement',
          buttonText: 'Upload',
          folder: 'residence-proof',
          verified: parseInt(String(residence_proof).length),
          name: "doc_por"
        },
        {
          itemHead: 'Upload Declaration of Deposit',
          itemContent: 'Document declaring deposit in account',
          buttonText: 'Upload',
          folder: 'dod',
          verified: parseInt(String(dod).length),
          name: "doc_dod"
        },
        {
          itemHead: 'Upload Bank Card',
          itemContent: 'Upload front of bank card',
          buttonText: 'Upload',
          folder: 'bank-card',
          verified: parseInt(String(bank_card).length),
          name: "doc_card"
        },
        {
          itemHead: 'Email Verification',
          itemContent: `Verify ${email}`,
          buttonText: 'Request Verification',
          verified: email_verified,
          name: "email_verified"
        },
      ];
  
  
      window.vdv = 0;
      window.lab = "";
      Object.entries(verificationData).forEach((v) => {
        if(v[1].verified > 0) {
          window.vdv += 1;
        } else if(!window.lab.length) {
          window.lab = v[1].name;
        }
      });
  
      let vdw = "Your document will be verified by our support team, this could take about 24-48 working hours after which your account will be activated.";
      let hed = "Verification required";
      let err = false;
  
      if(this.state.accu) {
        vdw = "Profile details updated!";
        hed = "Account Updated";
      }
  
      if(this.state.ever) {
        vdw = "Please verify your email account with the link we just sent your mail";
        hed = "Verify Email";
      }

      if(this.state.showCallBackTxt.length) {
        err = this.state.callBack_is_err;
        hed = "Account "+(err ? "not ": "")+"updated";
        vdw = this.state.showCallBackTxt;
      }
      
        return (
            <Container>
                <CallBack show={this.state.showCallBack} cancel={(e) => this.setState({showCallBack: false, showCallBackTxt: ''})} head={hed} text={vdw} error={err} />
        
                {showAddCardModal ? (
                    <AddCard
                      refresh={this.refreshCards}
                      handleClick={() => this.setState({showAddCardModal: false})}
                      showAddCardModal={showAddCardModal}
                    />
                ) : null}

                {showSpinner ? <Spinner showSpinner={showSpinner} /> : null}
    
                <div className="profile-page">
                    <div id="main-body" className={btab == "profile" ? '' : 'sh'}>
                      <div className={'pro-flex '+(btab == "kyc" ? 'pro-active' : 'pro-inactive')}>
                        <h3 className="astt">Account Status
                          <span style={{marginLeft: "1em", position: "relative", top: "-3px"}}>
                            {"12345".split("").map((i) => {
                              return <img src={Number(i) > window.vdv ? star : fstar} alt="" className="ast" />
                            })}
                          </span>
                        </h3>
                        {
                          kyc > 0 ? null : <div className="nbw">To verify your account, upload/take actions on the boxes with active elements. Items with green checkmarks have been filled.</div>
                        }
                        <div className="row v r">
                            <div className="col"><span><img src={check} /><b>Email Verification</b></span><button className={"btn"+(email_verified ? ' s' : '')} onClick={() => {
                              if(!email_verified) {
                                this.sendEOTP();
                              }}}><img src={upv} className="upv" /><span>{email_verified ? "Verified" : "Request"}</span></button></div>
                            {this.verificationBox({name: "doc_poi", folder: 'identity-proof', label: 'Proof of Identity', status: parseInt(String(identity_proof).length)})}
                            {this.verificationBox({name: "doc_por", folder: 'residence-proof', label: 'Proof of Residence', status: parseInt(String(residence_proof).length)})}
                            {this.verificationBox({name: "doc_card", folder: 'bank-card', label: 'Credit/Debit Card', status: parseInt(String(bank_card).length)})}
                            {this.verificationBox({name: "doc_dod", folder: 'dod', label: 'Declaration of Deposit', status: parseInt(String(dod).length)})}
                        </div>
                      </div>
                      <hr className='not-imobile' />
                      <div className={'pro-flex '+(btab == "bank" ? 'pro-active' : 'pro-inactive')}>
                        <AccountDetails
                            done={(e) => this.setState({accu: true, showCallBack: true, showSpinner: false})}
                            showSpinner={(e) => this.setState({showSpinner: !this.state.showSpinner})}
                        />
                        <hr className='not-imobile' />
                      </div>
                      <div className={'pro-flex '+(btab == "card" ? 'pro-active' : 'pro-inactive')}>
                        <h3 className="astt">Financial Details</h3>
                        <div className='my-cards' style={{position: "relative"}}>
                          <img src={add} className="tool-i" style={{width: "35px", height: "35px", top: "-30px", right: "20px"}} onClick={() => this.setState({showAddCardModal: true})} />
                          {cards
                            ? cards.map((data) => (
                                <DebitCard
                                  {...data}
                                  deleteCard={() => this.deleteCard(data.id, data.PAN)}
                                  key={`${Math.random()}1-${Math.random()}-${Math.random()}`}
                                />
                              ))
                            : null}
                        </div>
                      </div>

                    </div>
                    <div id="right-side-menu" className={btab == "profile" ? 'pro-active' : 'pro-inactive'}>
                        <div className='small-overlay' style={{ display: this.state.showSmallSPinner ? 'flex' : 'none' }}><div className='small-spinner'></div></div>
                        <img src={profile_image.length ? profile_image+"?t="+modify_time : avatar} alt="" className="avatar" />
                        <input
                          type='file'
                          name='myImage'
                          id='file'
                          accept='image/x-png,image/gif,image/jpeg'
                          onChange={this.handleFileChange}
                        />
                        <label htmlFor='file'><img src={upd} className="upd" style={{border: "1px solid #444", borderRadius: "50%"}} /></label>
                        <h3 className="p-name">{(first_name+" "+last_name).ucwords()}</h3>
                        <span className="acc-id">{app.uid(user_id)}</span>
                        <div className="block">
                            <ul>
                                <li><b>Email</b> <input type="email" name="email" className="editable" defaultValue={email} placeholder="----" /></li>
                                <li><b>Phone</b> <input type="text" name="phone_number" className="editable" defaultValue={phone_number} placeholder="----" /></li>
                                <li><b>Gender</b> <input value={gender.ucwords()} /></li>
                                <li><b>Language</b> <input value={lang} /></li>
                                <li><b>Country</b> <input value={country} /></li>
                                <li style={{marginBottom: "0px"}}><b>City</b> <input type="text" name="city" className="editable" defaultValue={city} placeholder="----" /></li>
                            </ul>
                            {updateC ? <button className="btn btn-warning btn-lg btn-block app-btn" style={{marginTop: "15px"}} onClick={() => this.updateDetails()}>Update</button> : null}{/* <img className="ed" src={ed} /> */}
                        </div>
                        
                        <form align="left" className="dform" onSubmit={(e) => {return false;}}>
                            <div className="form-group">
                                <label>OLD PASSWORD</label>
                                <input type="password" className="form-control" name="oldPassword" onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>NEW PASSWORD</label>
                                <input type="password" className="form-control" name="newPassword" onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>CONFIRM PASSWORD</label>
                                <input type="password" className="form-control" name="confirmPassword" onChange={this.handleInputChange} />
                            </div>
                            <p className='errorMessage'>{this.state.error}</p>
                            <p className='success-message' style={{ display: this.state.success ? 'block' : 'none' }} onClick={() => this.setState({success: false})}>
                                Password Changed succesfully
                            </p>
                            <div className="form-group" style={{marginTop: "15px"}}>
                                <button type="button" onClick={this.changePassword} className="btn btn-warning btn-lg btn-block app-btn">Save password</button>
                            </div>
                        </form>
                    </div>
                </div>
                <ul className="b-tabs alt">
                  <li className={"btab"+(btab == "profile" ? " _active" : "")} onClick={() => { this.setState({btab: "profile"}); }}><img src={btab == "profile" ? walletA : wallet} alt="" /><span>Profile</span></li>
                  <li className={"btab"+(btab == "kyc" ? " _active" : "")} onClick={() => { this.setState({btab: "kyc"}); }}><img src={btab == "kyc" ? walletA : wallet} alt="" /><span>KYC</span></li>
                  <li className={"btab"+(btab == "bank" ? " _active" : "")} onClick={() => { this.setState({btab: "bank"}); }}><img src={btab == "bank" ? bsellA : bsell} alt="" /><span>Bank</span></li>
                  <li className={"btab"+(btab == "card" ? " _active" : "")} onClick={() => { this.setState({btab: "card"}); }}><img src={btab == "card" ? bsellA : bsell} alt="" /><span>Card</span></li>
                </ul>
            </Container>
        );
    }
}

export default withRouter(Profile);